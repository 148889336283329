<template>
    <section class="section-default section-about">
        <div class="col-left">
            <div class="col-left-container">    
            <h2>Nous connaitre</h2>
            <p>Marjonat Distribution est une société innovante créée en 2014, qui propose une solution de régie publicitaire implantée directement dans les magasins pour permettre aux annonceurs de communiquer aux shoppers directement sur le lieu où ils sont susceptibles d’acheter leurs produits.</p>
            </div>
        </div>
        <div class="col-main">

        </div>
    </section>

    <section class="section-agency">
        <div class="box">
            <p>Au fil des années, <strong>Marjonat Distribution</strong> est également<br /> devenu une <strong>agence créative</strong>.</p>
            <router-link :to="{ name : 'Agency'}" class="btn-agency">En savoir +</router-link>
        </div>
    </section>

    <section class="section-value">
        <div class="box">
            <h2>Nos <strong>valeurs</strong></h2>
            <p>Nos valeurs définissent qui nous sommes. Elles sont le fondement de notre organisation.</p>
            <div class="icon-list">
                <div class="icon-item" v-for="(item, idx) in icons" :key="idx">
                    <img :src="item.img" alt="icon" />
                    <h3>{{ item.title}}</h3>
                    <p>{{ item.text}}</p>
                </div>
            </div>
        </div>
    </section>

    <section class="section-conv">
        <div class="box">
            <h2>Nos <strong>convictions</strong></h2>
            <p>Chez Marjonat Distribution, on aime nos clients.</p>
            <p class="featured">Le commerce est affaire de proximité, de relation, de création de lien humain et sincère.</p> 
        </div>
    </section>

    <section class="section-citation">
        <div class="box">
            <p>« <strong>Votre satisfaction est notre but quotidien</strong>, votre réussite d’aujourd’hui sera la notre de demain, <strong>le tout dans un climat de confiance et d’échange réciproque</strong>. »</p>
        </div>
    </section>
</template>

<script>
export default {
    name : "About",
    data() {
        return {
            icons : [
                {
                    img : require('@/assets/icon-gem.png'),
                    title : "Quête de l’excellence",
                    text : "Nous partageons tous cette volonté de nous dépasser pour offrir le meilleur à nos clients."
                },
                {
                    img : require('@/assets/icon-loup.png'),
                    title : "TRANSPARENCE",
                    text : "Nous devons toujours parler vrai, être sincère et être capables de justifier nos actions et nos décisions."
                },
                {
                    img : require('@/assets/icon-idea.png'),
                    title : "INNOVATION",
                    text : "Innover, c’est comprendre, créer et savoir évoluer."
                },
                {
                    img : require('@/assets/icon-hands.png'),
                    title : "RESPECT",
                    text : " respect de soi, des autres et de l’environnement accompagnent chacune de nos actions."
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";

    .section-about .col-left .col-left-container h2  {
        color:$mainColor;
    }

    .section-about .col-left .col-left-container p  {
        color:$secondColor;
    }


    .section-about .col-main {
        background-image: url("./../assets/bg-about.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    /* AGENCY */
    .section-agency {
        padding:80px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background:$mainColor;
    }

    .section-agency p {
        color:#fff;
        font-size:35px;
        text-align: center;
        line-height: 1.3em;
        text-transform: uppercase;
    }

    .btn-agency {
        margin-top:40px;
        border:1px solid #fff;
        border-radius:10px;
        background:#fff;
        color:$mainColor;
        padding:15px 30px;
        font-size:25px;
        font-weight:700;
        text-transform: uppercase;
        text-decoration: none;  
        transition: 0.5s;
    }

    .btn-agency:hover {
        background:$mainColor;
        color:#fff;
    }

    /* VALUE */

    .section-value {
        padding:80px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .section-value h2 {
        color:$secondColor;
        text-transform: uppercase;
        margin-bottom:40px;
        font-size:40px;
        font-weight:400;
        text-align: center;
    }

    .section-value p {
        text-align: center;
        color:$mainColor;
        font-size:30px;
        line-height:1.5em;
    }

    .icon-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        margin-top:80px;
    }

    .icon-item {
        width: 280px;
        margin:20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .icon-item img {
        display: block;
        width:80px;
        height:80px;

    }

    .icon-item h3 {
        margin:20px 0;
        color:$mainColor;
        text-align: center;
        font-size: 25px;
        font-weight:700;
        text-transform: uppercase;
    }

    .icon-item p {
        text-align: justify;
        color:$secondColor;
        font-size:18px;
    }

    /* CONV */

    .section-conv {
        padding:80px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background:$secondColor;
    }

    .section-conv h2 {
        color:#fff;
        text-transform: uppercase;
        margin-bottom:40px;
        font-size:40px;
        font-weight:400;
        text-align: center;
    }

    .section-conv p {
        text-align: center;
        color:#fff;
        font-size:30px;
        line-height:1.5em;
    }

    .section-conv p.featured {
        color:$thirdColor;
    }

    /* CITATION */

    .section-citation {
        padding:120px 0;
        background-image: url('./../assets/citation-bg.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .section-citation p {
        text-align: center;
        font-size:25px;
        font-style:italic;
        color:$secondColor;
        max-width: 1300px;
        line-height:1.5em;
    }

    @media screen and (max-width:1100px) {
        .section-about {
            padding-top:140px;
        }
    }

    @media screen and (max-width:768px) {
        .icon-item {
            margin:20px 0;
        }
    }

</style>